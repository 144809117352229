.header-view {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: rgb(0, 0, 0, 0.5);
	border-bottom: 1px solid #FFFFFF;
	z-index: 99;

	.header {
		position: relative;
		height: 80px;
		padding-left: 90px;
		padding-right: 28px;

		.menuList {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.menuItem {
				&:not(:first-child) {
					margin-left: 74px;
				}
				&:hover {
					div {
						color: #ffba01;
					}
				}
			}
		}

		.enterBtn {
			cursor: pointer;
			width: 206px;
			height: 43px;
			background: url(../../assets/images/enterBtn.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
		}
	}
}

@media only screen and (max-width: 1080px) {
	.header-view {
		.header {
			position: relative;
			height: 70px;
			padding-left: 0;
			padding-right: 0;
		}

		.menuList-m {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 70px;
			background: rgb(0, 0, 0, 0.5);
			z-index: 99;
		}
	}
}
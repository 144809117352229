* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Poppins-Regular";
  font-weight: 700;
  transition: all ease 0.33ms;
  color: #FFFFFF;
}

.font-Enter-The-Grid {
  font-family: 'Enter-The-Grid';
}

.font-Poppins-Regular {
  font-family: 'Poppins-Regular';
}

.font-Poppins-Light {
  font-family: 'Poppins-Light';
}

.font-Poppins-Medium {
  font-family: "Poppins-Medium";
}

.font-Furore {
  font-family: "Furore";
}

.container {
  width: 1366px;
  max-width: 100%;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-26 {
  margin-left: 26px;
}

.ml-36 {
  margin-left: 36px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 36px;
}

.font-60 {
  font-size: 60px;
}

.font-72 {
  font-size: 72px;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: #FEFEFE;
}

.color3 {
  color: #FFC300;
}

.color4 {
  color: #FBD002;
}

.color5 {
	color: #EE4D6E;
}

.color6 {
	color: #58ED4D;
}

.color7 {
	color: #E9BC4C;
}

.color8{
	color: #0C8DE1;
}

.color9 {
	color: #8A8A8A;
}

.color10 {
  color: #FF005C;
}

.font-weight-b {
  // font-variation-settings: "wght" 700;
  // font-weight: bolder;
  font-family: 'Poppins-Bold';
}

.font-weight-6 {
  font-weight: 600;
}

.font-weight-3 {
  font-weight: 300;
}

.border-radius-4 {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

.app-container {
  position: relative;
  overflow-x: hidden;
}

@media only screen and (max-width: 1080px) {
  .container {
    width: 375px;
  }

  .font-60 {
    font-size: 30px;
  }

  .font-72 {
    font-size: 36px;
  }
}
.b10_tveffect {
    position: relative;
    overflow: hidden;
}

.b10_tveffect div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.b10_tveffect div:nth-child(2) {
    overflow: hidden;
}

.b10_tveffect div:nth-child(2) div {
    position: absolute;
    top: 0;
    left: -0;
    text-align: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b10_tveffect div:nth-child(2) div:nth-child(1) {
    color: rgba(200, 120, 120, 0.8);
    opacity: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAGCAYAAAAL+1RLAAAAF0lEQVQIW2NkwAIYgWKS6OIgQQxAoUoAE4AAUnD/0ugAAAAASUVORK5CYII=) repeat;
}

.b10_tveffect div:nth-child(2) div:nth-child(2) {
    color: rgba(120, 200, 120, 0.8);
    opacity: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAGCAYAAAAL+1RLAAAAF0lEQVQIW2NkwAIYgWKS6OIgQQxAoUoAE4AAUnD/0ugAAAAASUVORK5CYII=) repeat;
}

.b10_tveffect div:nth-child(2) div:nth-child(3) {
    color: rgba(120, 120, 200, 0.8);
    opacity: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAGCAYAAAAL+1RLAAAAF0lEQVQIW2NkwAIYgWKS6OIgQQxAoUoAE4AAUnD/0ugAAAAASUVORK5CYII=) repeat;
}

.b10_tveffect:hover {
    background: #ff2f01 !important;
    transition: background-color 0.3s ease 0.2s;
}

.b10_tveffect:hover div:nth-child(1) {
    color: rgba(0, 0, 0, 1);
}

.b10_tveffect:hover div:nth-child(2) div:nth-child(1) {
    animation: b10_tveffect_red 0.25s linear;
}

.b10_tveffect:hover div:nth-child(2) div:nth-child(2) {
    animation: b10_tveffect_green 0.25s linear;
}

.b10_tveffect:hover div:nth-child(2) div:nth-child(3) {
    animation: b10_tveffect_blue 0.25s linear;
}

@keyframes b10_tveffect_red {

    from,
    to {
        opacity: 0;
    }

    25% {
        opacity: 1;
        transform: scale(1.8, 1.6) translate(-10px, 3px) skewX(30deg);
    }

    50% {
        opacity: 1;
        transform: scale(1.5, 1.2) translate(20px, 6px) skewX(5deg);
    }

    75% {
        opacity: 1;
        transform: scale(0.9, 1.8) translate(-16px, -1px) skewX(-20deg);
    }
}

@keyframes b10_tveffect_green {

    from,
    to {
        opacity: 0;
    }

    25% {
        opacity: 1;
        transform: scale(1.3, 1.5) translate(3px, 6px) skewX(25deg);
    }

    50% {
        opacity: 1;
        transform: scale(1.6, 1.1) translate(-16px, -5px) skewX(-15deg);
    }

    75% {
        opacity: 1;
        transform: scale(1, 1.8) translate(18px, 4px) skewX(15deg);
    }
}

@keyframes b10_tveffect_blue {

    from,
    to {
        opacity: 0;
    }

    25% {
        opacity: 1;
        transform: scale(1.2, 1.4) translate(-10px, -3px) skewX(-25deg);
    }

    50% {
        opacity: 1;
        transform: scale(1.9, 1.1) translate(16px, 3px) skewX(5deg);
    }

    75% {
        opacity: 1;
        transform: scale(1.3, 2) translate(-30px, -3px) skewX(20deg);
    }
}